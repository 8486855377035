import { CheckCircleOutlined } from '@ant-design/icons';
import type { ButtonProps } from 'antd';
import { Button } from 'antd';
import type { FunctionComponent } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
// import { LoadingOutlined } from '@ant-design/icons';

type Props = Omit<ButtonProps & React.RefAttributes<HTMLElement>, 'onClick'> & {
  // 顺序异步处理
  asyncProcesses?: ((
    event: React.MouseEvent<HTMLElement>,
    preProcessResult: any,
  ) => Promise<any>)[];
  // 处理完成判定，完成之后按钮不再活性(再点击处理)
  isProcessesEnd?: (processResults: any[]) => boolean;
  onProcessesEnd?: (processResults?: any[]) => void;
};

export const AsyncOnclickButton: FunctionComponent<Props> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [processesEnd, setProcessesEnd] = useState<boolean>(false);
  const [processSeqNo, setProcessNo] = useState<number>(0);
  const [processEndResults, setProcessEndResults] = useState<any[] | undefined>(undefined);
  const endLoading = (nowProcessNo: number) => {
    // 只有相同processNo的可以解除loading
    if (nowProcessNo === processSeqNo) {
      setLoading(false);
      const nextProcessNo = (processSeqNo + 1) % 1000;
      setProcessNo(nextProcessNo);
    }
  };
  const enterLoading = (nowProcessNo: number) => {
    setLoading(true);
    setProcessNo(nowProcessNo);
  };

  useEffect(() => {
    // 初始化
    if (processesEnd && props.onProcessesEnd !== undefined) {
      // 需要一定时间表示处理完成的按钮
      setTimeout(() => {
        props.onProcessesEnd!(processEndResults);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processesEnd]);
  return (
    <Button
      size="large"
      shape="round"
      loading={props.loading ?? loading}
      icon={
        props.loading ?? loading ? undefined : processesEnd ? <CheckCircleOutlined /> : props.icon
      }
      onClick={
        props.asyncProcesses === undefined
          ? undefined
          : async (e) => {
              enterLoading(processSeqNo);
              try {
                if (!loading && !processesEnd) {
                  const processResults = [];
                  let preProcessResult = undefined;
                  for (const process of props.asyncProcesses!) {
                    try {
                      preProcessResult = await process(e, preProcessResult);
                    } catch (error) {
                      preProcessResult = undefined;
                    }
                    processResults.push(preProcessResult);
                  }
                  if (props.isProcessesEnd !== undefined && props.isProcessesEnd(processResults)) {
                    setProcessEndResults(processResults);
                    setProcessesEnd(true);
                  }
                }
              } catch (error) {
              } finally {
                endLoading(processSeqNo);
              }
            }
      }
      {...props}
    >
      {props.children}
    </Button>
  );
};

export default AsyncOnclickButton;
