import type { ModelMetaData } from '@/services/utils/ModelMetaDataUtil';
import { ModelMetaDataUtil } from '@/services/utils/ModelMetaDataUtil';
import { ObjectUtils2 } from '@/services/utils/ObjectUtils2';
import type { IGetInitialProps } from 'umi';

export type CommonPageProp = {
  metaData?: ModelMetaData;
};

export type CommonPage<PageProp> = React.FC<PageProp & CommonPageProp> & {
  getInitialProps: IGetInitialProps;
};

const CommonPageGenerator = (
  pageComponent: React.FC<any & CommonPageProp> & { getInitialProps?: IGetInitialProps },
) => {
  const pageComponentWithCommonData = (props: any) => {
    if (ObjectUtils2.isNullOrEmpty(props.metaData) && !ModelMetaDataUtil.initialized()) {
      // getInitialProps无法保证完全执行完才会渲染
      // 所以在执行完成或者ModelMetaDataUtil.initialized()以前不渲染pageComponent
      return () => undefined;
    } else if (!ObjectUtils2.isNullOrEmpty(props.metaData)) {
      ModelMetaDataUtil.initByData(props.metaData);
      return pageComponent(props);
    } else {
      return pageComponent(props);
    }
    // return pageComponent(props);
    // if (ObjectUtils2.isNullOrEmpty(props.metaData) && !ModelMetaDataUtil.initialized()) {
    //   // getInitialProps无法保证完全执行完才会渲染
    //   // 所以在执行完成以前不渲染pageComponent
    //   return () => undefined;
    // } else {
    //   ModelMetaDataUtil.initByData(props.metaData);
    // }
    // return pageComponent(props);
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  pageComponentWithCommonData.getInitialProps = (async (ctx) => {
    let oriProps = {};
    if (pageComponent.getInitialProps != undefined) {
      oriProps = await pageComponent.getInitialProps(ctx);
    }

    let metaData;
    if (!ModelMetaDataUtil.initialized()) {
      metaData = await ModelMetaDataUtil.getModelMetaData();
      // ModelMetaDataUtil.initByData(metaData);
    }
    return {
      metaData: metaData,
      ...oriProps,
    };
  }) as IGetInitialProps;
  return pageComponentWithCommonData as CommonPage<any>;
};

// Home.getInitialProps = (async (ctx) => {
//   const metaData = await ModelMetaDataUtil.getModelMetaData();
//   return {
//     metaData: metaData,
//   };
// }) as IGetInitialProps;

export default CommonPageGenerator;
