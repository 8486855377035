import React, { useContext } from 'react';
import type { FormProps } from 'antd';
import { Button, Card, Col, Form, notification, Row } from 'antd';
// TODO SSR实现
import { useRequest, useRouteMatch, history, Link } from 'umi';
import type { FormFieldType, FormType } from '@/components/QuudyForm/DefaultQuudyForm';
// import { DefaultQuudyForm } from '@/components/QuudyForm/DefaultQuudyForm';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

// import type { EventApprovalUserModel } from '@/services/datatypes/EventApprovalUser';
// import { ApplyUserKind } from '@/services/datatypes/EventApprovalUser';
// import type { ModelServerData } from '@/services/datatypes/BaseModel';
// import type { EventModel } from '@/services/datatypes/Event';
// import { notificationHandler } from '@/components/Common/ExceptionHandler';
import { AccountService } from '@/services/AccountService';
import LoadingIndicator from '@/components/QuudyLoadingPage/LoadingIndicator';
import { QuudyUserInfoContext } from '@/components/QuudyPageLayout';
// import type { LocationDescriptorObject } from 'history-with-query';
import { TextField } from '@/components/QuudyForm/TextField';
import AsyncOnclickButton from '@/components/AsyncOnclickButton';
import { ObjectUtils2 } from '@/services/utils/ObjectUtils2';
import type { LoginStateModel } from '@/services/datatypes/LoginState';
import { AddressConfirmStatus, userAddrConfirmStatus } from '@/services/datatypes/User';
import CommonPageGenerator from '../common';

// type PageEventInfo = {
//   event: ModelServerData<EventModel>;
//   eventApprovalUser: { eventApprovalUser: ModelServerData<EventApprovalUserModel> }[];
// };

type PageProp = {
  formInfo: FormType;
  // applyInfo?: PageEventInfo;
};

// function fillApplyForm(pageApplyInfo: PageEventInfo) {
//   let userId;
//   const adultListForm = [];
//   const childListForm = [];
//   let email;
//   let tel;
//   let address;
//   if (pageApplyInfo.eventApprovalUser.length > 0) {
//     const eventApprovalUser = pageApplyInfo.eventApprovalUser[0].eventApprovalUser;
//     userId = eventApprovalUser.userId;
//     email = eventApprovalUser.email;
//     tel = eventApprovalUser.tel;
//     address = eventApprovalUser.address;
//     for (const approvalUserModelTree of pageApplyInfo.eventApprovalUser) {
//       const approvalUser = approvalUserModelTree.eventApprovalUser;
//       if (approvalUser.kind === ApplyUserKind.ADULT) {
//         adultListForm.push({
//           apply_no: approvalUser.applyNo,
//           lastname: approvalUser.lastname,
//           lastname_kana: approvalUser.lastnameKana,
//           firstname: approvalUser.firstname,
//           firstname_kana: approvalUser.firstnameKana,
//           age: approvalUser.age,
//           company: approvalUser.company,
//           company_address: approvalUser.companyAddress,
//         });
//       } else if (approvalUser.kind === ApplyUserKind.CHILD) {
//         childListForm.push({
//           apply_no: approvalUser.applyNo,
//           lastname: approvalUser.lastname,
//           lastname_kana: approvalUser.lastnameKana,
//           firstname: approvalUser.firstname,
//           firstname_kana: approvalUser.firstnameKana,
//           age: approvalUser.age,
//           grade: approvalUser.grade,
//           school: approvalUser.school,
//           p_lastname: approvalUser.pLastname,
//           p_lastname_kana: approvalUser.pLastnameKana,
//           p_firstname: approvalUser.pFirstname,
//           p_firstname_kana: approvalUser.pFirstnameKana,
//         });
//       }
//     }
//   }
//   const form = {
//     event_id: pageApplyInfo.event.eventId,
//     user_id: userId,
//     adult_list_form: adultListForm,
//     child_list_form: childListForm,
//     email: email,
//     tel: tel,
//     address: address,
//   };
//   return form;
// }

const getInitialProps = async (eventId: string | undefined) => {
  // 动态页面数据
  if (eventId !== undefined && typeof eventId !== 'string') {
    // TODO TO ERROR PAGE
    throw new Error('url error');
  }
  const service = new AccountService();
  const formInfo = await service.loginFormInfo();
  return {
    formInfo: formInfo,
    applyInfo: undefined,
  } as PageProp;
};

type PageRouteParams = {
  eventId: string;
};

const Login: React.FC<any> = CommonPageGenerator(() => {
  // const { initialState } = useModel('@@initialState');
  const quudyUserContext = useContext(QuudyUserInfoContext)!;
  // const umiLocation = useLocation<{ redirectLocation?: LocationDescriptorObject }>();
  // const redirectLocation = umiLocation.state?.redirectLocation;
  // const redirectLocation = props.location?.state?.redirectLocation;
  const [form] = Form.useForm();
  const match = useRouteMatch<PageRouteParams>();
  // TODO useBreakpoint的响应式最初有一瞬间无法识别屏幕大小造成跳动。
  const screens = useBreakpoint();
  let formProp: FormProps;
  if (!screens.lg && !screens.xl) {
    // 大屏和超大屏以下时,标题表示在上方并且
    formProp = {
      labelCol: {
        span: 24,
      },
      wrapperCol: {
        span: 24,
      },
      layout: 'vertical',
    };
  } else {
    formProp = {
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 16,
      },
      layout: 'horizontal',
    };
  }
  const { data, error, loading } = useRequest<PageProp>(
    async () => {
      const initProps = await getInitialProps(match.params.eventId);
      return initProps;
    },
    { formatResult: (res: PageProp) => res },
  );
  if (error) {
    // return <div>error</div>;
    throw error;
  }
  if (screens.xs === undefined) {
    // 桌面版的useBreakPoint会先执行一遍mobile的breakpoint之后再渲染实际桌面宽度的画面
    // 渲染完xs是必有值的
    // 因此跳过，否则会发生无用的请求
    return <></>;
  }
  let pageContent;
  if (loading) {
    return <LoadingIndicator />;
  } else {
    const { formInfo } = data as PageProp;
    // const modify = applyInfo !== undefined;
    // const initFormValues = modify ? fillApplyForm(applyInfo) : undefined;
    pageContent = (
      <>
        <Card
          key="page-card"
          title={
            <span style={{ fontWeight: 700, fontSize: 20, color: 'white' }}>{'ログイン'}</span>
          }
          headStyle={{
            textAlign: 'center',
            backgroundColor: '#2689ff',
          }}
        >
          <Form
            form={form}
            // initialValues={initFormValues}
            name={formInfo.formName}
            autoComplete="off"
            scrollToFirstError={true}
            {...formProp}
          >
            <TextField
              // ニックネーム
              key={(formInfo.fields[0] as FormFieldType).fieldName}
              formFieldData={formInfo.fields[0] as FormFieldType}
            />
            <TextField
              // メールアドレス
              key={(formInfo.fields[1] as FormFieldType).fieldName}
              formFieldData={formInfo.fields[1] as FormFieldType}
              formProps={{
                extra: <Link to={'/reminder/'}>パスワードを忘れた</Link>,
              }}
            />
          </Form>
          {/* <DefaultQuudyForm
            initFormInfo={formInfo}
            initFormInstance={form}
          // initFormValues={initFormValues}
          /> */}
          <Row
            key={'form-button-row-1'}
            style={{
              marginTop: 10,
              marginBottom: 15,
            }}
          >
            <Col
              // offset={8} span={8}
              style={{
                width: '100%',
                textAlign: 'center',
              }}
            >
              <AsyncOnclickButton
                style={{
                  minWidth: '33%',
                  textAlign: 'center',
                }}
                asyncProcesses={[
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  async (_, __) => {
                    try {
                      const formValues = await form.validateFields();
                      return formValues;
                    } catch (submitError) {
                      const firstErrorFieldName = (submitError as any).errorFields?.[0]?.name;
                      if (firstErrorFieldName !== null) {
                        form.scrollToField(firstErrorFieldName);
                      }
                      console.error(submitError);
                      return undefined;
                    }
                  },
                  async (_, preProcessResult) => {
                    if (preProcessResult !== undefined) {
                      // const result = await notificationHandler(async () => {
                      //   return loginState;
                      // });
                      const loginState = await new AccountService().accountLogin(preProcessResult);
                      if (!(loginState instanceof Error)) {
                        await quudyUserContext.refreshQuudyUserInfo();
                        return loginState;
                      } else {
                        return undefined;
                      }
                    }
                    return undefined;
                  },
                ]}
                isProcessesEnd={(processResults) => {
                  if (
                    processResults.length == 2 &&
                    !ObjectUtils2.isNullOrEmpty(processResults[1])
                  ) {
                    return true;
                  } else {
                    return false;
                  }
                }}
                onProcessesEnd={(processResults) => {
                  const loginState = processResults?.[1] as LoginStateModel | undefined;
                  if (loginState !== undefined) {
                    if (loginState.insDate.value == loginState.updDate.value) {
                      const userInfo = quudyUserContext.quudyUserInfo?.[0];
                      if (userInfo !== undefined) {
                        const addrConfirmStatus = userAddrConfirmStatus(userInfo);
                        if (
                          addrConfirmStatus ==
                          AddressConfirmStatus.ADDRESS_CONFIRM_STATUS_POP_QR_CODE_SCAN
                        ) {
                          history.push('/roomconfirm/');
                        }
                      }
                    }
                  }
                  notification.info({
                    placement: 'bottomLeft',
                    message: 'ログインに成功しました',
                  });
                }}
              >
                {'ログイン'}
              </AsyncOnclickButton>
            </Col>
          </Row>
          <Row
            key={'form-button-row-2'}
            style={{
              marginTop: 10,
              marginBottom: 15,
            }}
          >
            <Col
              // offset={8} span={8}
              style={{
                width: '100%',
                textAlign: 'center',
              }}
            >
              <Button
                style={{
                  minWidth: '33%',
                  // width: '100%',
                  textAlign: 'center',
                }}
                size="large"
                shape="round"
                onClick={async () => {
                  history.push('/entry');
                }}
              >
                {'新規登録'}
              </Button>
            </Col>
          </Row>
          {/* <Row
            key={'form-button-row-2'}
            style={{
              marginTop: 10,
              marginBottom: 15,
            }}
          >
            <Col
              // offset={8} span={8}
              style={{
                width: '100%',
                textAlign: 'center',
              }}
            >
              <Button
                style={{
                  minWidth: '33%',
                  // width: '100%',
                  textAlign: 'center',
                }}
                size="large"
                shape="round"
                onClick={async () => {
                  history.push('/neighborhoodlogin');
                }}
              >
                {'町会ログイン'}
              </Button>
            </Col>
          </Row> */}
        </Card>
      </>
    );
  }
  return pageContent;
});

export default Login;
